import React from "react";
import RequestButton from "../RequestButton";

const BackPainInfo = () => {
  return (
    <div className="w-full flex flex-col justify-center items-center bg-white">
      <div className="w-4/5 lg:w-1/2 flex flex-col justify-start items-start py-12">
        <p className="text-3xl font-thin text-gray-800 mb-6">Back Pain Q & A</p>

        <p className="text-xl text-gray-800 mb-2">What is back pain?</p>
        <p className="text-md text-gray-600 mb-12">
          Back pain might result from a sports injury, car accident, disease,
          falling down, or other trauma. It’s a feeling of discomfort that can
          linger long-term or come and go. Back pain might subside over time or
          get worse. Seeking treatment at Sunstate Medical Associates offers you
          relief and a better quality of life.
        </p>

        <p className="text-xl text-gray-800 mb-2">
          What are the symptoms of back pain?
        </p>
        <p className="text-md text-gray-600 mb-12">
          Back pain can feel like shooting or stabbing pain, dull aching pain,
          muscle aches, or radiating pain down your leg. Movement can make back
          pain worse. If the discomfort doesn’t go away on its own, see the
          Sunstate Medical Associates team for an evaluation.
        </p>

        <p className="text-xl text-gray-800 mb-2">What causes back pain?</p>
        <p className="text-md text-gray-600 mb-2">
          Numerous injuries and diseases can contribute to back pain, including:
        </p>
        <ul className="text-gray-600 text-md list-inside list-disc pl-4 mb-2">
          <li>Scoliosis</li>
          <li>Spinal stenosis</li>
          <li>Sciatica</li>
          <li>Osteoporosis</li>
          <li>Arthritis</li>
          <li>Muscle strains</li>
          <li>Ligament strains</li>
          <li>Bulging discs</li>
          <li>Muscle spasms</li>
        </ul>
        <p className="text-md text-gray-600 mb-12">
          Finding the root cause of back pain helps your primary care doctor
          develop a treatment plan that’s right for you.
        </p>

        <p className="text-xl text-gray-800 mb-2">
          What are the risk factors for back pain?
        </p>
        <p className="text-md text-gray-600 mb-2">
          Anyone can experience back pain but certain factors increase your
          chance of developing it. Examples include:
        </p>
        <ul className="text-gray-600 text-md list-inside list-disc pl-4 mb-2">
          <li>Sedentary lifestyle</li>
          <li>Older age</li>
          <li>Excess weight</li>
          <li>Smoking</li>
          <li>Improper lifting techniques</li>
          <li>Depression</li>
          <li>Anxiety</li>
        </ul>
        <p className="text-md text-gray-600 mb-12">
          While you can’t eliminate back pain risk factors entirely, reduce your
          risk by getting regular exercise, maintaining a healthy weight, using
          good posture, eating a healthy diet, and not smoking.
        </p>

        <p className="text-xl text-gray-800 mb-2">
          How is back pain diagnosed?
        </p>
        <p className="text-md text-gray-600 mb-12">
          To figure out what’s causing back pain, your primary care specialist
          asks about your symptoms and medical history. They complete a physical
          exam and might have you move into various positions to evaluate pain
          and tenderness. Your doctor can use blood tests, X-rays, CT scans,
          MRIs, nerve tests, and bone scans to make a final diagnosis.
        </p>

        <p className="text-xl text-gray-800 mb-2">How is back pain treated?</p>
        <p className="text-md text-gray-600 mb-3">
          The team of specialists at Sunstate Medical Associates may recommend
          the following treatments to relieve back discomfort:
        </p>
        <p className="text-lg text-gray-700 mb-2">Conservative treatments</p>
        <p className="text-md text-gray-600 mb-3">
          Conservative back pain treatments include resting your back, applying
          heat or ice, wearing a brace, and physical therapy. Your doctor shows
          you which stretches and strength exercises can offer relief.
          Therapeutic massage or chiropractic care can help, too.
        </p>
        <p className="text-lg text-gray-700 mb-2">Medications/injections</p>
        <p className="text-md text-gray-600 mb-3">
          Anti-inflammatory medications and injections can reduce inflammation
          associated with certain diseases and injuries.
        </p>
        <p className="text-lg text-gray-700 mb-2">Surgical procedures</p>
        <p className="text-md text-gray-600 mb-3">
          If you have a severe back injury, or conservative treatments and
          anti-inflammatory medications haven’t worked, your doctor can refer
          you to a highly skilled specialist in your area.
        </p>
        <p className="text-md text-gray-600 mb-10">
          Don’t let back pain diminish your quality of life and mobility.
          Schedule an appointment with Sunstate Medical Associates over the
          phone or online today for relief.
        </p>

        <RequestButton />
      </div>
    </div>
  );
};

export default BackPainInfo;
