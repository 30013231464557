import React from "react";
import Layout from "../../components/Layout";
import PageHeader from "../../components/PageHeader";
import Conditions from "../../components/Conditions";
import ServicesHeader from "../../components/ServicesHeader";
import BackPainInfo from "../../components/services/BackPainInfo";

const BackPain = () => {
  return (
    <Layout>
      <PageHeader text="Back Pain Specialist" />
      <ServicesHeader>
        Back pain can feel debilitating and diminish your quality of life. If
        back discomfort is long-lasting or severe, the expert internal medicine
        team at Sunstate Medical Associates in Lake Mary, Florida, has
        solutions. They provide a wide array of treatments to accelerate healing
        and offer back pain relief. Call the office today to speak with a
        friendly team member or book using the online scheduler.
      </ServicesHeader>
      <BackPainInfo />
      <Conditions />
    </Layout>
  );
};

export default BackPain;
